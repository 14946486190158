<template>
    <div class="qui_sommes_nous">
        <div class="text">
            <img src="@/assets/logoabsl+.png" alt="logo absl">
            <h2>25 ans d’expérience professionnelle dans l'anti-squat</h2>
            <h3>Un savoir-faire reconnu dans la protection de site et de terrain  sur toute L’IDF.</h3>
            <p class="sous-titre">Ils nous font confiance, SNCF, Nexity, RATP, Grand Paris Aménagement, CPAM 93, Sequano, OPH Ivry, Huissiers, Agences immobilières, particuliers…</p>
            <div class="qualite">
                <p v-scroll-reveal="{ duration: 800, delay: 50, origin: 'bottom', distance: '50px'}">Nos qualités :</p>
                <ol>
                    <li v-scroll-reveal="{ duration: 800, delay: 150, origin: 'bottom', distance: '50px'}">1-	La <strong>réactivité</strong>, une prise en charge du besoin avant 12 H, site sécurisé dans la journée</li>
                    <li v-scroll-reveal="{ duration: 800, delay: 250, origin: 'bottom', distance: '50px'}">2-	<strong>L’expertise</strong>, du sur mesure, ABSL s’adapte à toute les situations</li>
                    <li v-scroll-reveal="{ duration: 800, delay: 350, origin: 'bottom', distance: '50px'}">3-	La <strong>simplicité</strong>, autorisations administratives, rapport d’activité, suivi des sites nous assurons l’ensemble des prestations</li>
                    <li v-scroll-reveal="{ duration: 800, delay: 450, origin: 'bottom', distance: '50px'}">4-	A votre <strong>écoute</strong> selon vos besoins avec la Solution Global Service</li>
                </ol>
            </div>
        </div>
        <div class="informations" v-scroll-reveal="{ duration: 800, delay: 550, origin: 'bottom', distance: '50px'}">
            <p>ABSL une entreprise à taille humaine</p>
            <p>CNAPS n°</p>
            <p>3 sites sur l’IDF 93, 77, 94</p>
            <p>Des collaborateurs régulièrement formés et discrets.</p>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    .qui_sommes_nous {
        text-align: center;

        .text {
            width: 90%;
            margin: auto;
        }
        img {
            margin-top: 30px;
            width: 30%;
        }
        h2 {
            margin-top: 20px;
            text-transform: uppercase;
        }
        h3 {
            font-size: 16px;
            color: #3c97f7;
            font-weight: bold;
        }
        .sous-titre {
            margin-top: 20px;
            font-weight: 500;
        }
        .qualite {
            margin-top: 50px;
            p {
                font-size: 20px;
                text-transform: uppercase;
                font-weight: bold;
                color: #3c97f7;
                margin-bottom: 15px;
            }
            ol {
                list-style-type: none;

                strong {
                    color: #3c97f7;
                }
                li {
                    margin-bottom: 30px;
                    font-weight: 500;
                }
            }
        }
        .informations {
            padding: 20px 0;
            margin-top: 60px;
            width: 100%;
            background-color: #3c97f7;

            p {
                line-height: 30px;
                font-weight: bold;
                color: #fff;
            }
        }
    }
    @media screen and (max-width: 715px) {
        .qui_sommes_nous {
            h2 {
                font-size: 21px;
            }
            h3, .sous-titre, .qualite ol, .informations p {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 415px) { 
        h2 {
            margin-bottom: 10px;
        }
    }
</style>